import { Chart } from "react-google-charts";
import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(7),
    borderRadius: theme.spacing(10),
    [theme.breakpoints.down("md")]: {
      borderRadius: theme.spacing(0),
    },
  },
}));

const GeoChart = ({ data }) => {
  const classes = useStyles();

  if (!data?.length) {
    return null;
  }

  const options = {
    chart: {
      title: "Ingresos por país",
    },
    colors: ["#009933"],
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h4" gutterBottom>
        Reproducciones alrededor del mundo
      </Typography>
      <Chart
        chartType="GeoChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
      />
    </Paper>
  );
};

export default GeoChart;
