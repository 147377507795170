import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { functions } from "../../firebase";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import SwapHorizontalCircle from "@material-ui/icons/SwapHorizontalCircle";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import Divider from "@material-ui/core/Divider";
import PaymentStatusChip from "../reporting/PaymentStatusChip";
import localizerFactory from "../../utils/localizerFactory";
import {
  CircularProgress,
  TableContainer,
  Box,
  Slide,
} from "@material-ui/core";
import { PaymentForm } from "../reporting/payment-form";
import { addNotice, addError } from "../flash-messages";
import AppConfig from "../AppConfig";
import { values, orderBy, keys } from "lodash";

const localMoney = localizerFactory();

const MIN_PAYMENT = 10;

let isPRODUCTION;

switch (window.location.hostname) {
  case "subidalibre.musik.digital":
  case "therecordingconsort.musik.digital":
  case "musik.digital":
  case "www.musik.digital":
    isPRODUCTION = true;
    break;

  default:
    isPRODUCTION = false;
    break;
}

const reqPay = functions.httpsCallable("requestPayment2");

const useStyles = makeStyles((theme) => ({
  root: {
    color: "blue",
    minWidth: "50vw",
    minHeight: "50vh",
    padding: theme.spacing(3),
    borderRadius: theme.spacing(10),
    [theme.breakpoints.down("md")]: {
      borderRadius: theme.spacing(0),
    },
  },
  highlight: {
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.9),
  },
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    gap: 0,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: theme.spacing(1),
    },
  },
  tableHeaderTitles: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(1),
    },
  },
  arrowWay: {
    position: "absolute",
    left: 0,
    top: "65%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    fontSize: theme.spacing(5),
    opacity: 0.7,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  arrow: {
    color: "rgb(204, 170, 102)",
  },
  hideOnMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

/* table head column config */
const colHeads = [
  {
    id: "periodpublished",
    numeric: false,
    disablePadding: true,
    label: "Publicados en",
  },
  {
    id: "periodgenerated",
    numeric: false,
    disablePadding: true,
    label: "Generados en",
  },
  { id: "total", numeric: true, disablePadding: true, label: "Regalías" },
  { id: "status", numeric: false, disablePadding: true, label: "Estado" },
];

const PaymentTable = ({ data, reload }) => {
  const [selected, setSelected] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [working, setWorking] = useState(true);
  const [numSelected, setNumSelected] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [startSwipeArrow, setStartSwipeArrow] = useState(false);
  const [swipeDirection, setSwipeDirection] = useState("right");

  const classes = useStyles();

  useEffect(() => {
    if (keys(data).length) {
      setWorking(false);
      setTimeout(() => setStartSwipeArrow(true), 2000);
      setTimeout(() => {
        setSwipeDirection("left");
        setStartSwipeArrow(false);
      }, 5000);
    }
  }, [data]);

  useEffect(() => {
    setNumSelected(selected.length);
  }, [selected]);

  useEffect(() => {
    setRowCount(values(data).filter((x) => x.status === "unpaid").length);
  }, [data]);

  const requestPayment = async () => {
    try {
      setWorking(true);

      const payments = values(data).filter((x) => selected.includes(x.period));
      const result = await reqPay(payments);

      if (result.data === "ok") {
        setSelected([]);
        setSelectedAmount(0);
        setWorking(false);
        reload();

        addNotice({
          text: "Pago solicitado correctamente.",
        });
      } else {
        addError({
          text: "Error en la solicitud, por favor ponte en contacto con nosotros.",
        });
      }

      setWorking(false);
    } catch (e) {
      addError({
        text: "Error en la solicitud, por favor ponte en contacto con nosotros.",
      });
    }
  };

  const handleSelectAllClick = (event) => {
    const unpaid = values(data).filter((x) => x.status === "unpaid");

    if (event.target.checked) {
      setSelected(unpaid.map((n) => n.period));
      setSelectedAmount(unpaid.reduce((t, n) => t + n.total, 0));
    } else {
      setSelected([]);
      setSelectedAmount(0);
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    const newSelectedAmount = values(data)
      .filter((x) => newSelected.includes(x.period))
      .reduce((t, x) => t + x.total, 0);

    setSelected(newSelected);
    setSelectedAmount(newSelectedAmount);
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <Paper className={classes.root}>
      <Box className={classes.arrowWay}>
        <Slide
          direction={swipeDirection}
          in={startSwipeArrow}
          mountOnEnter
          unmountOnExit
        >
          <SwapHorizontalCircle fontSize="inherit" className={classes.arrow} />
        </Slide>
      </Box>

      <Toolbar>
        <div className={classes.tableHeader}>
          <div className={classes.tableHeaderTitles}>
            <Typography variant="h6" id="tableTitle" color="textPrimary">
              Resumen mensual de ingresos
            </Typography>

            <Typography variant="subtitle1" color="textPrimary">
              Selecciona los períodos que quieres incluir en el pago.
            </Typography>
          </div>

          <Tooltip
            title={`El pago mínimo es $${MIN_PAYMENT}`}
            placement="top-start"
            enterDelay={100}
          >
            <div>
              {working ? (
                <CircularProgress variant="indeterminate" />
              ) : (
                <AppConfig>
                  <PaymentForm
                    requestPayment={requestPayment}
                    working={working}
                    numSelected={selected.length}
                    selectedAmount={selectedAmount}
                    MIN_PAYMENT={MIN_PAYMENT}
                    localMoney={localMoney}
                    aria-label="Payment"
                  ></PaymentForm>
                </AppConfig>
              )}
            </div>
          </Tooltip>
        </div>
      </Toolbar>

      <Divider />

      <TableContainer className={classes.tableWrapper}>
        <Table
          stickyHeader
          className={classes.table}
          aria-labelledby="tableTitle"
        >
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={numSelected > 0 && numSelected === rowCount}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              {colHeads.map((row) => (
                <TableCell
                  key={row.id}
                  align={
                    row.id === "status" || row.id === "total" ? "right" : "left"
                  }
                  padding="normal"
                  className={row.hide ? classes.hideOnMobile : ""}
                >
                  {row.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {orderBy(values(data), ["period"], ["desc"])
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((n) => {
                const isThisSelected = isSelected(n.period);
                const isPayable = n.status === "unpaid" && n.total > 0;

                return (
                  <TableRow
                    hover={isPayable}
                    onClick={
                      isPayable ? (event) => handleClick(event, n.period) : null
                    }
                    role="checkbox"
                    aria-checked={isThisSelected}
                    tabIndex={-1}
                    key={n.period}
                    selected={isThisSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox checked={isThisSelected} />
                    </TableCell>

                    <TableCell padding="normal" align="left">
                      <Typography noWrap>{n.periodAccountingHuman}</Typography>
                    </TableCell>

                    <TableCell padding="normal" align="left">
                      <Typography noWrap>{n.periodHuman}</Typography>
                    </TableCell>

                    <TableCell padding="normal" align="right">
                      {localMoney.format(n.total)}
                    </TableCell>

                    <TableCell padding="normal" align="right">
                      <PaymentStatusChip statusKey={n.status} />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[15]}
        component="div"
        count={values(data).length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          "aria-label": "Previous Page",
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page",
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default PaymentTable;
