import { Chart } from "react-google-charts";
import { Paper, Divider, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FraudChart from "./FraudChart";
import FraudByTrack from "./FraudByTrack";
import Donut from "./DonutChart";
import HelpFraudChartDialog from "./HelpFraudChartDialog";
import FraudLight from "./FraudLight";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(7),
    borderRadius: theme.spacing(10),
    [theme.breakpoints.down("md")]: {
      borderRadius: theme.spacing(0),
    },
  },
  chartBox: {
    marginBottom: theme.spacing(5),
  },
  fraudChartTitleAndButton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: theme.spacing(5),
  },
}));

const FraudSection = ({ data }) => {
  const classes = useStyles();

  if (data?.fraudulentTracks?.length === 0) {
    return null;
  }

  return (
    <Paper className={classes.root}>
      <Typography variant="h4" gutterBottom>
        Actividad fraudulenta
      </Typography>

      <FraudLight data={data} />

      <Typography variant="body1" gutterBottom>
        Si estás viendo esta sección es porque las plataformas han detectado y
        reportado actividad fraudulenta en tu contenido.
      </Typography>

      <div style={{ marginTop: ".5em", marginBottom: "2em" }}>
        <HelpFraudChartDialog />
      </div>

      <Divider />

      <FraudChart data={data} />

      <Divider />

      <FraudByTrack data={data} />

      <Divider />

      <Donut
        data={data.donut}
        title="Reproducciones fraudulentas por track (detectadas por las plataformas)"
      />
    </Paper>
  );
};

export default FraudSection;
