import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { keys } from "lodash";
import Semaforo from "./Semaforo";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    display: "flex",
    gap: theme.spacing(2),
  },
  light: {
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    backgroundColor: "green",
    marginRight: theme.spacing(2),
  },
}));

const USE_NUM_PERIODS = -3; // Use the last 3 periods

const FraudLight = ({ data }) => {
  const [percentage, setPercentage] = useState(0);
  const [color, setColor] = useState("green");
  // const [totalStreams, setTotalStreams] = useState(0);
  // const [totalFraudulent, setTotalFraudulent] = useState(0);

  const classes = useStyles();

  useEffect(() => {
    if (data) {
      const { fraudulentByPeriod, streamsByTrackAndPeriod } = data;

      const usePeriods = keys(fraudulentByPeriod)
        .slice(USE_NUM_PERIODS)
        .map((p) => parseInt(p));

      const totalFraudulent = usePeriods
        .map((period) => fraudulentByPeriod[period])
        .reduce((acc, curr) => acc + curr, 0);

      const totalStreamsCount = streamsByTrackAndPeriod
        .filter((p) => usePeriods.includes(p.period))
        .reduce((acc, curr) => acc + curr.cantidad, 0);

      const maxAllowedFraud = Math.round(totalStreamsCount * 0.01);

      const percentageNumber = parseFloat(
        (totalFraudulent / maxAllowedFraud) * 100
      );
      setPercentage(percentageNumber);

      let newColor = "green";
      if (percentageNumber > 30) newColor = "yellow";
      if (percentageNumber > 50) newColor = "red";
      setColor(newColor);
    }
  }, [data]);

  return (
    <div className={classes.root}>
      <Semaforo color={color} />

      <div className={classes.info}>
        <Typography variant="h4">{percentage.toFixed(0)}%</Typography>

        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          Del umbral máximo tolerable
        </Typography>

        <Typography variant="body1" color="textSecondary">
          👉🏾 <strong>PRO TIP:</strong>{" "}
          <a href="https://www.artist.tools/bot-checker" target="_blank">
            Utiliza esta herramienta para entender de dónde vienen.
          </a>
        </Typography>
      </div>
    </div>
  );
};

export default FraudLight;
