// @ts-check
import { db, firebase, functions } from "../firebase";

/** @typedef {import("../business-objects/album").Album} Album */

export class CreditNotEnoughError extends Error {}

/**
 * @param {string} dateStr Format yyyy-mm-dd
 * @param {number} daysUntil
 * @return {number}
 */
const _calcDaysDifference = (dateStr, daysUntil) => {
  const date = new Date(dateStr);
  const today = new Date();
  const rawDifference = today.getTime() - date.getTime();
  const difference = Math.round(rawDifference / (1000 * 60 * 60 * 24));
  return daysUntil - difference;
};

/**
 * @return {Promise<number>}
 */
export const fetchDaysFreeRemove = async () => {
  return firebase.db
    .ref("/config/album_remove_for_free_days")
    .once("value")
    .then((snap) => snap.val());
};

/**
 * @return {Promise<number>}
 */
export const fetchCancelPrice = async () => {
  return firebase.db
    .ref("/config/album_remove_price_credits")
    .once("value")
    .then((snap) => snap.val());
};

/**
 * Calc count days left to allow free remove an album
 * @param {Album} album
 * @return {Promise<number>}
 */
export const calcDaysFreeCancel = async (album) => {
  const daysToFreeRemove = await fetchDaysFreeRemove();
  const daysLeftToFree = _calcDaysDifference(
    album.release_digital,
    daysToFreeRemove
  );
  return daysLeftToFree;
};

export const simulateCancelBalance = async () => {
  const cancelPrice = await fetchCancelPrice();
  const balance = await db.getUserBalance();
  return balance - cancelPrice;
};

/**
 * Calc count days left to allow free remove an album
 * TODO: rename this methods, it's confusing
 *
 * @param {Album} album
 * @return {Promise<boolean>}
 */
export const cancel = async (album) => {
  const cancelPrice = await fetchCancelPrice();
  const balance = await db.getUserBalance();

  if (balance >= cancelPrice) {
    await db.cancelAlbumOnEdit(album.upc);
    await db.setUserBalance(-cancelPrice);
    return true;
  } else {
    throw new CreditNotEnoughError(
      "Su saldo es insuficiente, realice una recarga por favor."
    );
  }
};

/**
 *
 * @param {string} upc
 * @param {string} path
 * @return {Promise<any>}
 */
export const fetchUneditedField = async (upc, path) => {
  const fullFieldPath = `catalogUnedited/${upc}/${path}`;
  const fieldValue = await firebase.db
    .ref(fullFieldPath)
    .once("value")
    .then((snap) => snap.val());

  return fieldValue;
};

/**
 *
 * @param {string} upc
 * @returns {Promise<Album>}
 */
export const getAlbum = async (upc) => {
  return await firebase.db
    .ref(`catalog/${upc}`)
    .once("value")
    .then((snap) => {
      const albumSnap = snap.val();
      albumSnap.live = albumSnap.live ? albumSnap.live : false;
      return albumSnap;
    });
};

/**
 * Discard the changes of an editing album
 * Could be draft or live_updating
 *
 * @param {string} upc
 */
export const albumDiscardChanges = async (upc) => {
  const rpc = functions.httpsCallable("albumDiscardChanges");

  return rpc(upc);
};

/**
 * @param {string} upc
 * @return {Promise<any>}
 */
export const discardAlbum = async (upc) => {
  const uid = firebase.auth.currentUser.uid;
  const rpc = functions.httpsCallable("draftAlbumDiscard");

  return rpc({ uid, upc }).then((resp) => resp.data);
};

export default {
  getAlbum,
  fetchDaysFreeRemove,
  fetchCancelPrice,
  fetchUneditedField,
  simulateCancelBalance,
  calcDaysFreeCancel,
  cancel,
  albumDiscardChanges,
  discardAlbum,
};
