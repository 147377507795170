import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, makeStyles, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SortIcon from "@material-ui/icons/Sort";

import { getTracksSorted } from "../../../../../business-objects";
import albumActions from "../../../../../reducers/albumActions";
import useAlbumOpened from "../../../../../reducers/album/hooks/useAlbumOpened";
import useAlbumErrors from "../../../../../reducers/album/hooks/useAlbumErrors";

import TracksList from "./TracksList";
import TracksListSortable from "./TracksListSortable";

import * as routes from "../../../../../constants/routes";
import { trackOpenAction } from "../../../../../reducers/albumActions";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  tracksList: {
    "& .trackList__list": {
      paddingTop: 0,
      marginBottom: "1rem",
      "& .MuiListItemIcon-root": {
        minWidth: "2rem",
      },
    },
    "& .trackList__listHeader": {
      color: theme.palette.text.secondary,
      fontSize: "0.8rem",
      "& .MuiListItemText-primary": {
        fontSize: "0.8rem",
      },
    },

    "& .trackList__errorMessage": {
      marginBottom: "1rem",
    },
  },
  trackListEmpty: {
    color: theme.palette.text.secondary,
  },
  footerToolbar: {
    display: "flex",
    "& > *": {
      marginRight: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      gap: theme.spacing(1),
    },
  },
}));

const Tracks = ({ canAddRemoveTracks }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const album = useAlbumOpened();
  const classes = useStyles();
  const [sortMode, setSortMode] = useState(false);
  const { errors, setError } = useAlbumErrors();
  const tracks = getTracksSorted(album);

  const error = errors?.["tracks"];
  const hasError = !!error;

  useEffect(() => {
    const hasTracks = tracks?.length > 0 || false;
    if (hasTracks) {
      if (hasError) {
        setError("tracks", null);
      }
    } else if (!hasError) {
      setError("tracks", "Al menos tiene que haber 1 track");
    }
  }, [tracks, hasError, setError]);

  const handleOnAddTrack = async () => {
    const isrc = await dispatch(albumActions.trackAdd(album.upc));
    dispatch(trackOpenAction(isrc));
    history.push(routes.ALBUM_EDIT_TRACKS.replace(":upc", album.upc), {
      fromAlbumEditor: true,
    });
  };

  if (!album) {
    return <></>;
  }

  if (!tracks) {
    return <div className={classes.trackListEmpty}>Sin tracks</div>;
  }

  return (
    <div className={classes.tracksList}>
      {hasError && (
        <Typography className="trackList__errorMessage" color="error">
          {error}
        </Typography>
      )}

      {sortMode ? (
        <TracksListSortable album={album} />
      ) : (
        <TracksList album={album} showDelete={canAddRemoveTracks} />
      )}

      {canAddRemoveTracks && (
        <div className={classes.footerToolbar}>
          {!sortMode && (
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="primary"
              size="small"
              onClick={handleOnAddTrack}
            >
              Añadir track
            </Button>
          )}
          {!sortMode && (
            <Button
              startIcon={<SortIcon />}
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setSortMode(true)}
              disabled={tracks.length < 2}
            >
              Ordenar tracks
            </Button>
          )}

          {sortMode && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setSortMode(false)}
            >
              Finalizar el orden
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default Tracks;
