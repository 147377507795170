import React, { useEffect, useState } from "react";
import { functions } from "../../../../firebase";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { Avatar, Badge, Button, ButtonBase, Tab } from "@material-ui/core";
import { deepOrange, grey, yellow } from "@material-ui/core/colors";
import VerificationStatus from "./VerificationStatus";
import Switch from "@material-ui/core/Switch";
import AlbumStatusesBar from "./AlbumStatusesBar";
import AlbumIcon from "@material-ui/icons/Album";
import PeopleIcon from "@material-ui/icons/People";
import LabelIcon from "@material-ui/icons/Label";
import IndicatorButton from "./IndicatorButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Bola from "./Bola";
import useConfig from "../../../../hooks/useConfig";
// import Fingerprint from "./Fingerprint";
import UltimatumSentIcon from "@material-ui/icons/Sports";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  parentRow: {
    backgroundColor: yellow[50],
  },
  statusIndicatorCell: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statusIndicatorOn: {
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor: "green",
  },
  statusIndicatorOff: {
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor: "red",
  },
  smallCell: {
    width: 15,
  },
  groupAvatar: {
    backgroundColor: deepOrange[500],
  },
  email: {
    maxWidth: 200,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  groupBox: {
    marginBottom: theme.spacing(1),
  },
}));

// backend
const applyRestriction = functions.httpsCallable("applyRestriction");
const blacklistAccount = functions.httpsCallable("blacklistAccount");
const sendUltimatum = functions.httpsCallable("sendUltimatum");

function ParentRow({ group, open, setOpen, updateAccountStatus }) {
  const [loading, setLoading] = useState(false);
  const [lists, setLists] = useState({});
  const [contentIdDistribution, setContentIdDistribution] = useState("on");
  const [cidButtonLabel, setCidButtonLabel] = useState("ALL OFF");
  const [accountsSummaryState, setAccountsSummaryState] = useState("on");
  const [accountsSummaryButtonLabel, setAccountsSummaryButtonLabel] =
    useState("ALL OFF");
  const [code, setCode] = useState("");

  const parent = group[0];

  const config = useConfig();

  const classes = useRowStyles();

  const switchAllContentId = async () => {
    setLoading(true);

    const action = contentIdDistribution === "on" ? "disable" : "enable";

    await applyRestriction({
      uid: parent.uid,
      restriction: "content_id_distribution_group",
      action,
    });

    // await update(group.map((u) => u.uid));

    setLoading(false);
  };

  const switchAllAccount = async () => {
    setLoading(true);

    const action = accountsSummaryState === "off" ? "enable" : "disable";

    await applyRestriction({
      uid: parent.uid,
      restriction: "group",
      action,
    });

    await Promise.all(group.map((u) => updateAccountStatus(u.uid, action)));

    // await updateAccountStatus(group.map((u) => u.uid));

    setLoading(false);
  };

  const listOf = (type) => {
    return group.reduce((acc, user) => [...acc, ...user.catalog[type]], []);
  };

  // albums, artists, labels
  useEffect(() => {
    const lists = {
      albums: [],
      artists: [],
      labels: [],
    };

    const isUnprocessedAlbum = (album) =>
      !["draft", "submitted", "received"].includes(album.status);

    Object.keys(lists).forEach((type) => {
      switch (type) {
        case "albums":
          lists.albums = listOf(type)
            .filter(isUnprocessedAlbum)
            .map((album) => album.title);
          break;
        case "artists":
          lists.artists = listOf(type).map((artist) => artist.name);
          break;
        case "labels":
          lists.labels = listOf(type);
          break;
        default:
          break;
      }
    });

    setLists(lists);
  }, [group]);

  // content id distribution group state
  useEffect(() => {
    if (group) {
      const { on, off } = group.reduce(
        (acc, user) => {
          if (!user.restrictions?.contentId) {
            acc.on.push(user.uid);
          } else {
            acc.off.push(user.uid);
          }
          return acc;
        },
        { on: [], off: [] }
      );

      if (on.length && off.length) {
        setContentIdDistribution("mix");
        setCidButtonLabel("ALL OFF");
      }
      if (on.length && !off.length) {
        setContentIdDistribution("on");
        setCidButtonLabel("ALL OFF");
      }
      if (!on.length && off.length) {
        setContentIdDistribution("off");
        setCidButtonLabel("ALL ON");
      }
    }
  }, [group]);

  useEffect(() => {
    if (group) {
      const { on, off } = group.reduce(
        (acc, user) => {
          if (!user.authUser?.disabled) {
            acc.on.push(user.uid);
          } else {
            acc.off.push(user.uid);
          }
          return acc;
        },
        { on: [], off: [] }
      );

      if (on.length && off.length) {
        setAccountsSummaryState("mix");
        setAccountsSummaryButtonLabel("ALL OFF");
      }
      if (on.length && !off.length) {
        setAccountsSummaryState("on");
        setAccountsSummaryButtonLabel("ALL OFF");
      }
      if (!on.length && off.length) {
        setAccountsSummaryState("off");
        setAccountsSummaryButtonLabel("ALL ON");
      }
    }
  }, [group]);

  useEffect(() => {
    if (parent.phone) {
      const prefix = /^\+\d{1,3}/.test(parent.phone)
        ? parent.phone.match(/^\+\d{1,3}/)[0]
        : parent.phone.trim().substring(0, 3);

      if (prefix && config.countries) {
        if (prefix === "+1") setCode("US");
        else {
          const country = config.countries.find((c) => {
            return c.prefix === prefix;
          });

          if (country) setCode(country.code);
        }
      }
    }
  }, [config, parent.phone]);

  return (
    <TableRow className={classes.parentRow}>
      <TableCell padding="normal" width={80}>
        <ButtonBase onClick={() => setOpen(!open)}>
          <Avatar className={classes.groupAvatar}>{group?.length || 0}</Avatar>
        </ButtonBase>
      </TableCell>

      <TableCell padding="normal" width={200}>
        {getFlagEmoji(code)} {parent.phone}
      </TableCell>

      <TableCell padding="normal" width={60} align="center">
        <VerificationStatus user={parent} />
      </TableCell>

      <TableCell padding="normal" width={80} align="center">
        <Typography variant="subtitle1">
          {group.reduce((acc, u) => acc + u.royalties, 0)}€
        </Typography>
      </TableCell>

      <TableCell padding="normal" width={200}>
        <Box
          style={{
            width: "100%",
            whitespace: "nowrap",
            display: "flex",
            alignItems: "center",
            gap: 30,
            color: grey[400],
            paddingTop: 10,
          }}
        >
          <IndicatorButton key="albums" disabled={!lists.albums} lists={lists}>
            <Badge
              badgeContent={lists.albums?.length || 0}
              color="secondary"
              overlap="rectangular"
            >
              <AlbumIcon size="small" />
            </Badge>
          </IndicatorButton>

          <IndicatorButton
            key="artists"
            disabled={!lists.artists}
            lists={lists}
          >
            <Badge
              badgeContent={lists.artists?.length || 0}
              color="secondary"
              overlap="rectangular"
            >
              <PeopleIcon size="small" />
            </Badge>
          </IndicatorButton>

          <IndicatorButton key="labels" disabled={!lists.labels} lists={lists}>
            <Badge
              badgeContent={lists.labels?.length || 0}
              color="secondary"
              overlap="rectangular"
            >
              <LabelIcon size="small" />
            </Badge>
          </IndicatorButton>
        </Box>
      </TableCell>

      <TableCell padding="normal" align="center">
        &nbsp;
      </TableCell>

      <TableCell padding="normal" align="center">
        &nbsp;
      </TableCell>

      <TableCell
        padding="normal"
        align="left"
        width={120}
        style={{ whiteSpace: "nowrap" }}
      >
        <Button
          fullWidth
          color="primary"
          variant="contained"
          size="small"
          onClick={switchAllContentId}
        >
          {cidButtonLabel}
        </Button>
      </TableCell>

      <TableCell
        padding="normal"
        align="left"
        width={120}
        style={{ whiteSpace: "nowrap" }}
      >
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={switchAllAccount}
          size="small"
        >
          {accountsSummaryButtonLabel}
        </Button>
      </TableCell>

      <TableCell
        padding="normal"
        align="left"
        style={{ width: 45 }}
      ></TableCell>
    </TableRow>
  );
}

const getFlagEmoji = (countryCode) => {
  if (countryCode === undefined) return "";

  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
};

function getAvatarAlt(child) {
  const _ =
    child.firstnames || child.lastnames || child.email || child.authUser.email;

  return _[0].toUpperCase();
}

function ChildRow({
  child,
  CIDstatuses,
  updateAccountStatus,
  accountStatuses,
}) {
  const [loading, setLoading] = useState(false);
  const [ultimatumSent, setUltimatumSent] = useState(
    child.restrictions?.ultimatum || false
  );

  if (!child || typeof child === "undefined") return <></>;

  const classes = useRowStyles();

  const firstLetter = getAvatarAlt(child);

  const { artists, albums, labels } = child.catalog;

  const isBlacklisted = child.restrictions?.blacklisted || false;

  const isUnprocessedAlbum = (album) =>
    !["draft", "submitted", "received"].includes(album.status);

  const lists = {
    albums: (albums || []).filter(isUnprocessedAlbum).map((a) => a.title),
    artists: (artists || []).map((a) => a.name),
    labels: labels || [],
  };

  const switchContentId = async () => {
    setLoading(true);
    const action = CIDstatuses[child.uid] ? "enable" : "disable";

    const payload = {
      uid: child.uid,
      restriction: "content_id_distribution",
      action,
    };

    await applyRestriction(payload);

    setLoading(false);
  };

  const switchAccount = async () => {
    setLoading(true);

    // const action = child.authUser.disabled ? "enable" : "disable";

    const payload = {
      uid: child.uid,
      restriction: "user",
      // action,
    };

    const { data } = await applyRestriction(payload);

    if (data.success) {
      updateAccountStatus(child.uid, data.newStatus);
    }

    setLoading(false);
  };

  const copyToClipboard = async () =>
    await navigator.clipboard.writeText(child.uid);

  const handleUidClick = async (event) => {
    copyToClipboard();
  };

  const handleBlackList = async () => {
    setLoading(true);
    await blacklistAccount({ uid: child.uid });
    setLoading(false);
  };

  const ultimatum = async () => {
    setLoading(true);
    const result = await sendUltimatum({ uid: child.uid });

    if (result.data.success) {
      setUltimatumSent(true);
    }
    setLoading(false);
  };

  const ultimatumSentDialog = (ultimatum) => {
    alert(`Enviado ${ultimatum.when}`);
  };

  return (
    <TableRow key={child.uid} hover={true}>
      <TableCell
        size="small"
        padding="checkbox"
        align="right"
        style={{ width: 60 }}
      >
        {loading ? (
          <CircularProgress
            size={15}
            style={{ opacity: loading ? "1" : "0" }}
          />
        ) : (
          <Bola
            status={accountStatuses[child.uid]}
            CID={CIDstatuses[child.uid]}
          />
        )}
      </TableCell>

      <TableCell padding="normal" width={80}>
        <Avatar src={child.authUser.photoURL}>{firstLetter}</Avatar>
      </TableCell>

      <TableCell padding="normal" width={200} className={classes.email}>
        <div style={{ fontWeight: child.isRequested ? "bold" : 500 }}>
          {child.email || child.authUser.email}
        </div>
        <div style={{ fontSize: ".9em", opacity: 0.7 }}>{`${
          child.firstnames || ""
        } ${child.lastnames || ""}`}</div>
      </TableCell>

      <TableCell padding="normal" width={60} align="center">
        <VerificationStatus user={child} />
      </TableCell>

      <TableCell padding="normal" width={80} align="center">
        <Typography variant="subtitle1">{child.royalties}€</Typography>
      </TableCell>

      <TableCell padding="normal" width={200}>
        <Box
          style={{
            width: "100%",
            whitespace: "nowrap",
            display: "flex",
            gap: 30,
            color: grey[700],
            justifyContent: "space-around",
          }}
        >
          <IndicatorButton lists={lists} disabled={!albums.length}>
            <div>{albums.length}</div>
          </IndicatorButton>

          <IndicatorButton lists={lists} disabled={!artists.length}>
            <div>{artists.length}</div>
          </IndicatorButton>

          <IndicatorButton lists={lists} disabled={!labels.length}>
            <div>{labels.length}</div>
          </IndicatorButton>
        </Box>
      </TableCell>

      <TableCell padding="normal" width={60} align="center">
        <Tooltip
          title={
            ultimatumSent
              ? `Ultimatum enviado ${ultimatumSent.when}`
              : "Enviar ultimatum"
          }
          placement="top"
        >
          <IconButton
            onClick={
              ultimatumSent
                ? () => ultimatumSentDialog(ultimatumSent)
                : ultimatum
            }
          >
            <UltimatumSentIcon
              disabled={loading}
              fontSize="medium"
              color={ultimatumSent ? "secondary" : "primary"}
              style={ultimatumSent ? { color: "red" } : { cursor: "disabled" }}
            />
          </IconButton>
        </Tooltip>
      </TableCell>

      <TableCell padding="none" align="center" width={270}>
        <AlbumStatusesBar user={child} />
      </TableCell>

      <TableCell padding="normal" width={40} align="center">
        <Button
          size="small"
          color="primary"
          variant="text"
          onClick={handleUidClick}
        >
          UID
        </Button>
      </TableCell>

      <TableCell padding="normal" align="center">
        {isBlacklisted ? (
          <Typography variant="caption" color="textSecondary">
            BLACKLISTED
          </Typography>
        ) : null}
        {/* <Button
          size="small"
          color="primary"
          variant="text"
          // onClick={handleBlackList}
          disabled={loading}
        >
          {isBlacklisted ? "BLACKLISTED" : "BLACKLIST"}
        </Button> */}
      </TableCell>

      <TableCell
        padding="none"
        align="right"
        // width="80"
        style={{ whiteSpace: "nowrap" }}
      >
        <Typography variant="caption" color="textSecondary">
          Cont ID
        </Typography>
        <Switch
          color="primary"
          checked={!CIDstatuses[child.uid]}
          onChange={switchContentId}
          name="content-id-switch"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </TableCell>

      <TableCell padding="none" width={120} style={{ whiteSpace: "nowrap" }}>
        <Typography variant="caption" color="textSecondary">
          Account
        </Typography>
        <Switch
          color="primary"
          checked={accountStatuses[child.uid] === "enabled"}
          onChange={switchAccount}
          name="disableAccount"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </TableCell>
    </TableRow>
  );
}

function Group({
  group,
  updateGroup,
  CIDstatuses,
  updateAccountStatus,
  accountStatuses,
}) {
  const [open, setOpen] = React.useState(true);

  if (!group || group.length < 1) {
    return null;
  }

  const parent = group.find(
    (g) => !g.parentAccount || g.parentAccount === g.uid
  );
  const children = group.filter(
    (g) => g.parentAccount && g.parentAccount !== g.uid
  );
  const sorted = [parent, ...children];

  return (
    <>
      <ParentRow
        group={sorted}
        open={open}
        setOpen={setOpen}
        updateAccountStatus={updateAccountStatus}
        accountStatuses={accountStatuses}
        CIDstatuses={CIDstatuses}
      />

      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={11}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small">
              <TableBody>
                {sorted.map((child) => (
                  <ChildRow
                    key={child.uid}
                    child={child}
                    CIDstatuses={CIDstatuses}
                    updateAccountStatus={updateAccountStatus}
                    accountStatuses={accountStatuses}
                  />
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function CollapsibleTable({
  groups,
  updateGroup,
  CIDstatuses,
  updateAccountStatus,
  accountStatuses,
}) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableBody>
          {groups?.length
            ? groups.map((group) => (
                <Group
                  key={group[0].uid}
                  group={group}
                  updateGroup={updateGroup}
                  CIDstatuses={CIDstatuses}
                  updateAccountStatus={updateAccountStatus}
                  accountStatuses={accountStatuses}
                />
              ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
