import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import localizerFactory from "../../utils/localizerFactory";
import { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(8),
    borderRadius: theme.spacing(10),
    [theme.breakpoints.down("md")]: {
      borderRadius: theme.spacing(0),
    },
  },
  minitotal: {
    // marginBottom: theme.spacing(3),
  },
}));

const localMoney = localizerFactory();

const getTotal = (values) => {
  return values.reduce((acc, item) => {
    return isNaN(item) ? acc : acc + item;
  }, 0);
};

const PaymentChart = ({ data }) => {
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);
  const [tooltips, setTooltips] = useState(null);
  const [totalRoyalties, setTotalRoyalties] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (data) {
      // set labels and values for chart
      setLabels(data.labels);
      setValues(data.values);
      setTooltips(data.tooltips);

      // set total (sum of displayed royalties)
      const total = getTotal(data.values);
      setTotalRoyalties(total);

      // set loaded
      setLoaded(true);
    }
  }, [data]);

  if (!loaded) {
    return <h1>No data...</h1>;
  }

  const options = {
    responsive: true,
    elements: {
      point: {
        radius: 5,
      },
      line: {
        fill: true,
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Ingresos por período",
      },
      tooltip: {
        callbacks: {
          title: function ([{ dataIndex }]) {
            const { total } = tooltips[dataIndex];
            return `${total}`;
          },
          afterTitle: function ([{ dataIndex }]) {
            return "Ingresos correspondientes a:";
          },
          label: function ({ dataIndex }) {
            const info = tooltips[dataIndex];

            return `- Reproducciones de ${info.period}`;
          },
          afterLabel: function ({ dataIndex }) {
            const info = tooltips[dataIndex];

            return `- Publicados en ${info.periodAccounting}`;
          },
        },
      },
    },
  };

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Ingresos",
        data: values,
        borderColor: "green",
        backgroundColor: "green",
      },
    ],
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.minitotal}>
        <Typography variant="h4">
          {localMoney.format(totalRoyalties)}
        </Typography>
        <Typography variant="h6">Ingresos totales</Typography>
      </div>
      <Line options={options} data={chartData} />
    </Paper>
  );
};

export default PaymentChart;
