import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect } from "react";
import { keys } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  chartBox: {
    marginBottom: theme.spacing(5),
  },
}));

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
};

const FraudChart = ({ data }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (data) {
      const { fraudulentByPeriod, fraudulentByPeriodWithLabels } = data;

      const chartData = {
        labels: fraudulentByPeriodWithLabels.map((row) => row.periodHuman),
        datasets: [
          {
            label: "Reproducciones fraudulentas",
            data: keys(fraudulentByPeriod).map(
              (period) => fraudulentByPeriod[period]
            ),
            backgroundColor: "rgba(250, 0, 0, 1)",
          },
        ],
      };

      setChartData(chartData);
    }
  }, [data]);

  const classes = useStyles();

  if (!chartData) return null;

  return (
    <div className={classes.root}>
      <Typography variant="h5" gutterBottom>
        Reproducciones fraudulentas totales por período
      </Typography>

      <Bar data={chartData} options={options} />
    </div>
  );
};

export default FraudChart;
